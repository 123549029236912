import { useEffect , useState} from "react";
// import HeroSection from "./components/HeroSection";
// import HowItWorksCarousel from "./components/HowItWorksCarousel";
// import CTABanner from "./components/CTABanner";
// import UseCasesCarousel from "./components/UseCasesCarousel";
// import Footer from "./components/Footer";
// import ThemeToggle from "./components/ThemeToggle";
import HomePage from "./HomePage";
import HomePage2 from "./HomePage2";
import InfoPanel from "./components/InfoPanel";
import Timeline from "./components/Timeline";
import Relationships from "./components/Relationships";
// import { useGlobalStore } from "./states/useGlobalStore";
// import MapGL from "./MapGL";

// function App() {
//   return (
//     <div className="bg-neutral-light dark:bg-dark-background text-neutral-text dark:text-dark-text overflow-x-hidden transition-colors duration-300">
//       <header className="flex justify-between items-center p-4">
//         <h1 className="text-2xl cursor-default font-bold transition-transform transform hover:scale-105">echoplot.io</h1>
//         {/* <ThemeToggle /> */}
//       </header>
//       <HomePage />
//       <MapGL layers={layers} initialViewState={initialViewState} />

//     </div>
//   );
// }

// export default App;


import React from 'react';
import MapGL from './components/MapGL';
import Settings from './components/Settings';
import { GeoJsonLayer } from '@deck.gl/layers';
import useGlobalStore from "./states/useGlobalStore";
import LocationPanel from "./LocationPanel";
import ChatPanel from "./ChatPanel";
import ChatFAB from "./ChatFAB";
import TabbedPanel from "./TabbedPanel";

const data = {
  "name": "Prophet Muhammad",
  "location": [39.8262, 21.4225],
  "loc_confidence": 100,
  "zoom": 10,
  "type": "Historical Figure",
  "comment": "Comment on this result",
  "description": "Prophet Muhammad, known as the final prophet in Islam, was born in Mecca in 570 CE. He received divine revelations that became the Quran, forming the foundation of Islam. His teachings emphasized monotheism, social justice, and moral conduct, leading to the transformation of Arabian society and the creation of a global religious community.",
  "time": "570 CE – 632 CE",
  "people": [
    {
      "name": "Khadijah bint Khuwaylid",
      "time": "555 CE – 619 CE",
      "description": "The first wife of Muhammad and his strongest supporter in the early days of Islam. A wealthy merchant, Khadijah provided emotional and financial support, and she was the first person to accept his prophethood."
    },
    {
      "name": "Abu Bakr",
      "time": "573 CE – 634 CE",
      "description": "A close companion and early convert to Islam. Abu Bakr later became the first Caliph, leading the Muslim community after Muhammad's death."
    },
    {
      "name": "Ali ibn Abi Talib",
      "time": "600 CE – 661 CE",
      "description": "Muhammad's cousin and son-in-law. A key figure in early Islam and the fourth Caliph, he is also central to the origins of the Sunni-Shia division."
    },
    {
      "name": "Aisha bint Abu Bakr",
      "time": "613 CE – 678 CE",
      "description": "One of Muhammad's wives, renowned for her intelligence and contributions to Islamic jurisprudence and history."
    }
  ],
  "timeline": [
    {
      "name": "Birth",
      "time": "570 CE",
      "location": [39.8262, 21.4225],
      "zoom": 12,
      "location_confidence": 90,
      "description": "Born in Mecca into the Quraysh tribe. Orphaned at a young age, he was cared for by his grandfather and then his uncle, Abu Talib."
    },
    {
      "name": "Marriage to Khadijah",
      "time": "595 CE",
      "location": [39.8262, 21.4225],
      "zoom": 12,
      "location_confidence": 90,
      "description": "Married Khadijah, a successful merchant. This partnership provided stability and allowed Muhammad to reflect deeply on spiritual matters."
    },
    {
      "name": "First Revelation",
      "time": "610 CE",
      "location": [39.8404, 21.4515],
      "zoom": 14,
      "location_confidence": 80,
      "description": "At the age of 40, Muhammad received the first revelation of the Quran through the Angel Jibreel (Gabriel) in the Cave of Hira, marking the beginning of his prophethood."
    },
    {
      "name": "The Hijra (Migration)",
      "time": "622 CE",
      "location": [39.6111, 24.5247],
      "zoom": 10,
      "location_confidence": 95,
      "description": "Migrated to Medina with his followers to escape persecution in Mecca. This event marks the start of the Islamic calendar."
    },
    {
      "name": "Battle of Badr",
      "time": "624 CE",
      "location": [38.7922, 23.7816],
      "zoom": 11,
      "location_confidence": 85,
      "description": "A key victory for the Muslims against the Quraysh, solidifying Muhammad's leadership."
    },
    {
      "name": "Conquest of Mecca",
      "time": "630 CE",
      "location": [39.8262, 21.4225],
      "zoom": 12,
      "location_confidence": 90,
      "description": "Returned to Mecca with an army, peacefully taking the city and cleansing the Kaaba of idols."
    },
    {
      "name": "Farewell Pilgrimage and Sermon",
      "time": "632 CE",
      "location": [39.8262, 21.4225],
      "zoom": 12,
      "location_confidence": 90,
      "description": "Delivered a powerful sermon emphasizing equality, justice, and adherence to the Quran and Sunnah. Shortly after, he passed away in Medina."
    }
  ],
  "relationships": [
    {
      "name": "Mecca",
      "location": [39.8262, 21.4225],
      "zoom": 12,
      "location_confidence": 95,
      "type": "City",
      "description": "Muhammad's birthplace and the site of the Kaaba, a central point in Islamic worship."
    },
    {
      "name": "Medina",
      "location": [39.6111, 24.5247],
      "zoom": 11,
      "location_confidence": 95,
      "type": "City",
      "description": "The city where Muhammad established the first Islamic state and is buried."
    },
    {
      "name": "Jerusalem",
      "location": [35.235, 31.778],
      "zoom": 13,
      "location_confidence": 80,
      "type": "Religious Landmark",
      "description": "The site of Muhammad's Night Journey (Isra and Mi'raj), a significant event in Islamic tradition."
    }
  ],
  "cultural_significance": [
    {
      "name": "The Quran",
      "description": "The holy book of Islam, regarded as the word of God revealed to Muhammad over 23 years."
    },
    {
      "name": "Sunnah and Hadith",
      "description": "Muhammad's sayings and actions, which serve as a guide for Muslims in all aspects of life."
    }
  ],
  "modern_significance": [
    {
      "name": "Hajj",
      "description": "The annual pilgrimage to Mecca, retracing the steps of Muhammad and other key figures in Islamic history."
    },
    {
      "name": "Global Islam",
      "description": "Islam, founded by Muhammad, is now practiced by over 1.9 billion people worldwide, influencing cultures, laws, and societies."
    }
  ],
  "natural_features": [
    {
      "name": "Cave of Hira",
      "location": [39.8404, 21.4515],
      "zoom": 14,
      "location_confidence": 85,
      "description": "The location where Muhammad received the first revelation of the Quran."
    },
    {
      "name": "Mount Uhud",
      "location": [39.6082, 24.4802],
      "zoom": 13,
      "location_confidence": 80,
      "description": "A site near Medina where the Battle of Uhud took place, a key event in Muhammad's life."
    }
  ],
  "artifacts": [
    {
      "name": "Kaaba",
      "location": [39.8262, 21.4225],
      "zoom": 14,
      "location_confidence": 95,
      "description": "A sacred structure in Mecca central to Islamic worship, restored by Muhammad during the conquest of Mecca."
    },
    {
      "name": "Green Dome in Medina",
      "location": [39.6111, 24.5247],
      "zoom": 13,
      "location_confidence": 90,
      "description": "The tomb of Muhammad in the Al-Masjid an-Nabawi mosque, a major pilgrimage site."
    }
  ],
  "events": [
    {
      "name": "Night Journey (Isra and Mi'raj)",
      "time": "621 CE",
      "description": "A miraculous event in which Muhammad traveled to Jerusalem and ascended to the heavens."
    },
    {
      "name": "Battle of Badr",
      "time": "624 CE",
      "description": "A decisive victory that marked a turning point in the early Islamic community's fortunes."
    },
    {
      "name": "Treaty of Hudaybiyyah",
      "time": "628 CE",
      "description": "A peace agreement with the Quraysh, enabling greater propagation of Islam."
    },
    {
      "name": "Farewell Sermon",
      "time": "632 CE",
      "description": "Delivered during his final pilgrimage, summarizing key Islamic teachings and stressing unity and equality."
    }
  ]
};







const App = () => {

  const { homepage, setHomepage, setLocation,location } = useGlobalStore()
  const [isChatOpen, setIsChatOpen] = useState(true);



  // const data = {
  //   type: 'FeatureCollection',
  //   features: [
  //     {
  //       type: 'Feature',
  //       geometry: {
  //         type: 'Point',
  //         coordinates: [-122.41669, 37.7853],
  //       },
  //       properties: {
  //         name: 'Example Point',
  //       },
  //     },
  //   ],
  // };

  const layers = [
    // new GeoJsonLayer({
    //   id: 'geojson-layer',
    //   data,
    //   pickable: true,
    //   filled: true,
    //   pointRadiusMinPixels: 5,
    //   pointRadiusScale: 200,
    //   getFillColor: [255, 0, 0],
    //   getRadius: 10,
    //   onClick: ({ object }) =>
    //     object && alert(`Clicked on ${object.properties.name}`),
    // }),
  ];

  return (
    <div style={{}}>
      {/* <header
        className="z-10 fixed flex justify-between items-center p-4 right-0"
        onClick={() => setHomepage(true)}
      >
        <h1 className="text-6xl font-bold mb-6 leading-tight bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-sky-600 to-blue-500">EchoPlot.</h1>
      </header> */}

      {/* <Settings /> */}
      {/* <MapGL layers={layers} /> */}

      {/* <ChatFAB onClick={setIsChatOpen}/> */}
      <TabbedPanel 
        data={data}
        setLocation={setLocation}
        isOpen={!homepage}
        setHomepage={setHomepage}
      />
      

   


      {/* Left Panel */}
      {/* {!homepage && <div className="bg-stone-100 overflow-x-hidden overflow-y-auto top-0 fixed bg-opacity-80 backdrop-blur-md shadow-md md:w-[450px] space-y-6 p-5 h-[100vh]">
        <div
          onClick={() => setLocation({ longitude: data.location[1], latitude: data.location[0], zoom: data.zoom })}
          className="p-4 space-y-4 bg-stone-50 cursor-default rounded shadow-md transition-transform transform hover:scale-105">
          <h2 className="text-2xl font-bold drop-shadow-md">{data.name}</h2>
          <p className="text-stone-600">{data.description}</p>
          <p className="text-sm text-stone-500">Type: {data.type}</p>
          <p className="text-sm text-stone-500">
            Location: {data.location[1]}, {data.location[0]} (Confidence:{" "}
            {data.loc_confidence}%)
          </p>
        </div>
        <h3 className="text-xl font-semibold drop-shadow-sm text-stone-800 mb-6">Timeline</h3>
        <ol className="relative border-s border-stone-400 dark:border-stone-700">
          {data.timeline.map((event, index) => (
            <li
              key={index}
              onClick={() => setLocation({ longitude: event.location[1], latitude: event.location[0], zoom: event.zoom })}
              // onClick={() => setLocation([event.location[1], event.location[0]])}
              className="mb-6  ms-4 cursor-pointer transition-transform transform hover:scale-105"
            >
              <div className="-mx-4 absolute w-3 h-3 bg-stone-400 rounded-full mt-1.5 -start-1.5 border border-white dark:border-stone-900 dark:bg-stone-700"></div>
              <time className="mb-1 text-sm font-normal leading-none text-stone-600 dark:text-stone-500">
                {event.time}
              </time>
              <h3 className="text-md mb-1 font-semibold text-stone-900 dark:text-white">
                {event.name || "Event Title"}
              </h3>

              <p className="mb-1 text-base font-normal text-stone-600 dark:text-stone-400">
                {event.description}
              </p>

              <p className="text-sm text-stone-400">
                Location: {event.location[1]}, {event.location[0]} (Confidence: {event.location_confidence}%) {event.zoom}
              </p>
            </li>
          ))}
        </ol>

        <div className="space-y-4">
          <h3 className="text-xl font-semibold">Related Places</h3>
          <ul className="space-y-2">
            {data.relationships.map((relation, index) => (
              <li
                key={index}
                // onClick={() => setLocation([relation.location[1], relation.location[0]])}
                onClick={() => setLocation({ longitude: relation.location[1], latitude: relation.location[0], zoom: relation.zoom })}
                className="p-2 bg-stone-50 rounded shadow transition-transform transform hover:scale-105 cursor-pointer"
              >
                <h4 className="font-bold">{relation.name}</h4>
                <p className="text-sm">{relation.description}</p>
                <p className="text-xs text-stone-500">
                  Location: {relation.location[1]}, {relation.location[0]}  (Confidence: {relation.location_confidence}%)
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>} */}


      {/* <div className="w-full h-96 border border-gray-300 rounded shadow"> */}
      <MapGL
        location={data.location}
        name={data.name}
        description={data.description}
        relationships={data.relationships}
      />
      {/* </div> */}

      {homepage && <HomePage2 />}
    </div>
  );
};

export default App;
