import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useGlobalStore = create(
  // persist(
  (set, get) => ({
    // ViewState: initial map settings
    viewState: {
      longitude: -122.41669,
      latitude: 37.7853,
      zoom: 13,
      pitch: 0,
      bearing: 0,
    },
    location: { longitude: -122.41669, latitude: 37.7853, zoom: 14 },
    homepage: true,
    setLocation: (newLocation) =>
      set((state) => ({ ...state, location: newLocation })),
    setHomepage: (value) => set({ homepage: value }),
    setViewState: (newViewState) =>
      set((state) => ({ ...state, viewState: newViewState })),

    // Future state variables
    userPreferences: {
      theme: 'light',
    },
    setUserPreferences: (preferences) =>
      set((state) => ({
        ...state,
        userPreferences: { ...state.userPreferences, ...preferences },
      })),

    activeLayer: null,
    setActiveLayer: (layer) =>
      set((state) => ({ ...state, activeLayer: layer })),
  }),
  {
    name: 'global-state-storage', // Key in localStorage
    getStorage: () => localStorage,
  }
  // )
);

export default useGlobalStore;
