import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Send, Bot, User, Loader } from 'lucide-react';

const ChatPanel = ({ isOpen, setIsOpen, hideHeader = false }) => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!input.trim()) return;

        const userMessage = {
            role: 'user',
            content: input
        };

        setMessages(prev => [...prev, userMessage]);
        setInput('');
        setIsLoading(true);

        // Simulate API call - replace with actual LLM API call
        setTimeout(() => {
            const botMessage = {
                role: 'assistant',
                content: 'This is a simulated response. Replace this with actual LLM integration.'
            };
            setMessages(prev => [...prev, botMessage]);
            setIsLoading(false);
        }, 1000);
    };

    const MessageBubble = ({ message }) => (
        <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className={`flex gap-2 ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}
        >
            {message.role === 'assistant' && (
                <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center flex-shrink-0">
                    <Bot className="w-5 h-5 text-blue-600" />
                </div>
            )}
            <div 
                className={`
                    max-w-[80%] p-3 rounded-lg 
                    ${message.role === 'user' 
                        ? 'bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-br-none' 
                        : 'bg-white border border-blue-100 rounded-bl-none'
                    }
                `}
            >
                <p className={`text-sm ${message.role === 'user' ? 'text-white' : 'text-slate-600'}`}>
                    {message.content}
                </p>
            </div>
            {message.role === 'user' && (
                <div className="w-8 h-8 rounded-full bg-gradient-to-r from-blue-600 to-blue-500 flex items-center justify-center flex-shrink-0">
                    <User className="w-5 h-5 text-white" />
                </div>
            )}
        </motion.div>
    );

    return (
        <div className="flex flex-col h-full bg-gradient-to-b from-sky-50/50 to-white">
            <div className="flex-1 overflow-y-auto p-4 space-y-4">
                {messages.length === 0 ? (
                    <div className="h-full flex items-center justify-center text-slate-400 text-center p-4">
                        <div className="space-y-3">
                            <Bot className="w-12 h-12 mx-auto text-blue-300" />
                            <p>Ask anything about this location's history, culture, or significance.</p>
                        </div>
                    </div>
                ) : (
                    messages.map((message, index) => (
                        <MessageBubble key={index} message={message} />
                    ))
                )}
                
                {isLoading && (
                    <div className="flex gap-2 items-center">
                        <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center">
                            <Bot className="w-5 h-5 text-blue-600" />
                        </div>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            className="bg-white border border-blue-100 rounded-lg p-3 rounded-bl-none"
                        >
                            <Loader className="w-5 h-5 text-blue-600 animate-spin" />
                        </motion.div>
                    </div>
                )}
                <div ref={messagesEndRef} />
            </div>

            <form 
                onSubmit={handleSubmit}
                className="p-4 bg-white border-t border-blue-100"
            >
                <div className="flex gap-2">
                    <input
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        placeholder="Ask about this figures, locations and events..."
                        className="flex-1 p-3 border border-blue-100 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500/20 bg-white text-sm"
                    />
                    <button
                        type="submit"
                        disabled={!input.trim() || isLoading}
                        className="p-3 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-md hover:from-blue-500 hover:to-blue-400 disabled:opacity-50 disabled:cursor-not-allowed transition-all shadow-lg shadow-blue-500/20 disabled:shadow-none"
                    >
                        <Send className="w-5 h-5" />
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ChatPanel;